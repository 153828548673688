<template>
  <!-- 新闻详情 -->
  <div class="press-details">
    <div class="main-box">
      <div class="returnCrumbs">
        <el-button type="text" @click="$router.push({name:'home'})">首页</el-button>
        <span> > </span>
        <el-button type="text" @click="$router.push({path:'/pressCenter',query:{type:newsDetail.type}})">{{newsType(newsDetail.type)}}</el-button>
        <span> > </span>
        <el-button type="text" class="crumbsActive">详情</el-button>
      </div>
      <div class="title">{{newsDetail.title}}</div>
      <div class="info">
        <span>发布日期: {{newsDetail.publishTime}}</span>
        <span>类型: {{newsType(newsDetail.type)}}</span>
        <span>来源: {{newsDetail.source}}</span>
      </div>
      <div class="ql-snow content">
        <div class="ql-editor" v-html="newsDetail.content"></div>
      </div>
      <div class="link">
        <router-link v-if="preNewsEntity.id" :to="{name:'pressDetails',query:{id:preNewsEntity.id}}">上一篇: {{preNewsEntity.title}}</router-link>
        <a v-else href="javascript:;">上一篇: {{preNewsEntity.title}}</a>
        <router-link v-if="nextNewsEntity.id" :to="{name:'pressDetails',query:{id:nextNewsEntity.id}}">下一篇: {{nextNewsEntity.title}}</router-link>
        <a v-else href="javascript:;">下一篇: {{nextNewsEntity.title}}</a>
      </div>
    </div>

  </div>
</template>
<style scoped>
@import '~@/assets/styles/quill.snow.css';
</style>
<script>
import { newsType } from '@/utils/typeMatch'
export default {
  data () {
    return {
      newsDetail: {},
      newsType,
      nextNewsEntity: {},
      preNewsEntity: {}
    }
  },
  mounted () {
    let id = this.$route.query.id
    this.getNewsDetail(id)
  },
  methods: {
    getNewsDetail (id) {
      this.$api.getNewsDetail({ id }).then(res => {
        this.newsDetail = res.data.newsEntity
        this.nextNewsEntity = res.data.nextNewsEntity
        this.preNewsEntity = res.data.preNewsEntity
      })
    }
  },
  watch: {
    $route () {
      let id = this.$route.query.id
      this.getNewsDetail(id)
    }
  }
}
</script>

<style lang="scss" scoped>
.press-details {
  padding-top: 86px;
  background: #f9fbff;
  padding-bottom: 160px;
  .back {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #8d96a3;
    line-height: 19px;
    display: flex;
    align-items: center;
    margin-bottom: 39px;
    cursor: pointer;
    .icon {
      transform: rotate(90deg);
      margin-right: 8px;
      font-size: 12px;
      vertical-align: middle;
    }
    &:hover {
      color: $primaryColor;
    }
  }
  .title {
    font-size: 30px;
    font-weight: bold;
    color: #303b50;
    line-height: 30px;
    letter-spacing: 3px;
    margin-bottom: 42px;
  }
  .info {
    font-size: 14px;
    color: #8d96a3;
    line-height: 14px;
    letter-spacing: 1px;
    margin-bottom: 32px;
    span {
      margin-right: 32px;
    }
  }
  .content {
    border-top: 1px #dfe7ff solid;
    border-bottom: 1px #dfe7ff solid;
    padding: 40px 0;
    margin-bottom: 40px;
  }
  .link {
    display: flex;
    justify-content: space-between;
    & > a {
      max-width: 336px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 14px;
    }
  }
}
.returnCrumbs {
  margin-bottom: 39px;
}
</style>
